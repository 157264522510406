import '../../../common/RCE/ricos.global.scss';

import type { RichContent } from '@wix/ricos';
import { WixRicosViewer } from '@wix/ricos';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import type { FC } from 'react';
import React from 'react';

import {
  useDefaultBIParams,
  useInstance,
  useMember,
} from '../../../../../../contexts/widgets';
import { biSettings, plugins } from './config';

export const Viewer: FC = () => {
  const { isMobile, language } = useEnvironment();
  const { instance } = useInstance();
  const { defaultBIParams } = useDefaultBIParams();
  const { experiments } = useExperiments();
  const { id, about } = useMember();

  return (
    <WixRicosViewer
      theme={{
        palette: {
          type: 'rgb',
          textColor: 'var(--section-description-color)',
        },
        typography: {
          fontFamily: 'var(--sectionDescriptionFont-family)',
        },
        customStyles: {
          p: {
            fontSize: 'var(--sectionDescriptionFont-size)',
          },
        },
      }}
      isMobile={isMobile}
      plugins={plugins}
      instance={instance}
      wixExperiments={experiments}
      biSettings={{
        ...biSettings,
        postId: id ?? '',
        defaultParams: defaultBIParams,
      }}
      content={about as unknown as RichContent}
      locale={language}
      linkSettings={{ rel: { nofollow: true } }}
    />
  );
};
