import { Origin } from '@wix/ambassador-members-v1-custom-field/types';
import type { TFunction } from '@wix/yoshi-flow-editor';

export const fieldLabel =
  (t: TFunction) => (origin: Origin, key: string, name: string) => {
    if (origin !== Origin.CUSTOM && t(key) !== key) {
      return t(key);
    }
    return name;
  };
