import type { FC } from 'react';
import React from 'react';
import { Text, Tooltip } from 'wix-ui-tpa/cssVars';

import type { DataHook } from '../../../../../types';
import { classes } from './StatisticalUnit.st.css';

export interface StatisticalUnitProps {
  className: string;
  dataHook: DataHook;
  tooltipText: string;
  count: number;
  title: string;
}

const thousand = 1000;
const million = Math.pow(thousand, 2);
const billion = Math.pow(thousand, 3);

const toPrettyNumber = (value: number) => {
  if (value >= billion) {
    return `${Math.floor(value / billion)}B`;
  } else if (value >= million) {
    return `${Math.floor(value / million)}M`;
  } else if (value >= thousand) {
    return `${Math.floor(value / thousand)}k`;
  }

  return value;
};

export const StatisticalUnit: FC<StatisticalUnitProps> = ({
  className,
  dataHook,
  tooltipText,
  count,
  title,
}) => {
  return (
    <Tooltip
      className={className}
      content={tooltipText}
      placement="top-start"
      minWidth={200}
    >
      <Text data-hook={dataHook} className={classes.content}>
        <span>{toPrettyNumber(count)}</span>
        &nbsp;
        <span className={classes.title}>{title}</span>
      </Text>
    </Tooltip>
  );
};
