import type { FC, MouseEventHandler } from 'react';
import React from 'react';

import type {
  InjectedClassNameProps,
  InjectedDataHookProps,
} from '../../../types';

export type ButtonClickHandler = MouseEventHandler<HTMLButtonElement>;

interface ButtonProps extends InjectedClassNameProps, InjectedDataHookProps {
  ariaLive?: 'off' | 'assertive' | 'polite';
  isDisabled?: boolean;
  onClick: ButtonClickHandler;
}

const Button: FC<React.PropsWithChildren<ButtonProps>> = ({
  className = '',
  dataHook,
  ariaLive,
  isDisabled = false,
  children,
  onClick,
}) => (
  <button
    className={className}
    data-hook={dataHook}
    type="button"
    aria-live={ariaLive}
    disabled={isDisabled}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;
