import type { WidgetProps } from '@wix/yoshi-flow-editor';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import type { FC } from 'react';
import React from 'react';
import { Divider, TPAComponentsProvider } from 'wix-ui-tpa/cssVars';

import { ModalsContextProvider } from '../../../contexts/modals';
import { ToastContextProvider } from '../../../contexts/toast';
import { WidgetContextProvider } from '../../../contexts/widgets';
import type { ControllerProps } from '../../../types';
import { DataHook } from '../../../types';
import Badges from './Badges';
import { useBadgesRenderer } from './Badges/hooks';
import BlogPosts from './BlogPosts';
import { useBlogPostsRendered } from './BlogPosts/hooks';
import Section from './common/Section';
import Description from './Description';
import { useDescriptionRenderer } from './Description/hooks';
import EmptyState from './EmptyState';
import {
  useBlockedEmptyStateRenderer,
  useEmptyStateRenderer,
} from './EmptyState/hooks';
import Header from './Header';
import Overview from './Overview';
import { useOverviewRenderer } from './Overview/hooks';
import { classes, st } from './Widget.st.css';

const getInfoSectionContentState = (
  shouldDisplayOverviewAsSidebar: boolean,
  shouldRenderOverview: boolean,
) => ({
  fullWidth: !shouldDisplayOverviewAsSidebar || !shouldRenderOverview,
});

const getInfoSectionSidebarState = (
  shouldDisplayOverviewAsSidebar: boolean,
  shouldRenderInfoSectionContent: boolean,
) => ({
  fullWidth: !shouldRenderInfoSectionContent || !shouldDisplayOverviewAsSidebar,
});

const Widget: FC<WidgetProps<ControllerProps>> = () => {
  const { shouldRenderDescription } = useDescriptionRenderer();
  const { shouldRenderBadges } = useBadgesRenderer();
  const { shouldRenderOverview } = useOverviewRenderer();
  const { shouldRenderBlogPosts } = useBlogPostsRendered();
  const { shouldRenderEmptyState } = useEmptyStateRenderer({
    shouldRenderDescription,
    shouldRenderBadges,
    shouldRenderOverview,
    shouldRenderBlogPosts,
  });
  const { shouldRenderBlockedEmptyState } = useBlockedEmptyStateRenderer();
  const { isMobile, isRTL, isEditorX } = useEnvironment();

  const shouldRenderInfoSectionContent =
    shouldRenderDescription || shouldRenderBadges;

  const shouldDisplayOverviewAsSidebar = !isMobile || isEditorX;

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
      })}
      dir={isRTL ? 'rtl' : 'ltr'}
      data-hook={DataHook.WidgetWrapper}
    >
      <Header />
      {(!isMobile ||
        shouldRenderBlockedEmptyState ||
        shouldRenderEmptyState) && <Divider className={classes.divider} />}
      {shouldRenderBlockedEmptyState || shouldRenderEmptyState ? (
        <EmptyState isBlocked={shouldRenderBlockedEmptyState} />
      ) : (
        <>
          <div
            className={st(classes.infoSectionsWrapper, {
              childrenFullWidth: !shouldDisplayOverviewAsSidebar,
              responsive: isEditorX,
            })}
          >
            {shouldRenderInfoSectionContent && (
              <div
                className={st(
                  classes.infoSectionContent,
                  getInfoSectionContentState(
                    shouldDisplayOverviewAsSidebar,
                    shouldRenderOverview,
                  ),
                )}
              >
                {shouldRenderDescription && (
                  <Section>
                    <Description />
                  </Section>
                )}
                {shouldRenderBadges && (
                  <Section>
                    <Badges />
                  </Section>
                )}
              </div>
            )}
            {shouldRenderOverview && (
              <Section
                className={st(
                  classes.infoSectionSidebar,
                  getInfoSectionSidebarState(
                    shouldDisplayOverviewAsSidebar,
                    shouldRenderInfoSectionContent,
                  ),
                )}
              >
                <Overview />
              </Section>
            )}
          </div>
          {shouldRenderBlogPosts && (
            <Section>
              <BlogPosts />
            </Section>
          )}
        </>
      )}
    </div>
  );
};

const WidgetWrapper: FC<WidgetProps<ControllerProps>> = (props) => {
  const { isMobile, isRTL } = useEnvironment();

  if (!props.member?.id) {
    return null;
  }

  return (
    <WidgetContextProvider {...props}>
      <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRTL }}>
        <ModalsContextProvider>
          <ToastContextProvider>
            <Widget {...props} />
          </ToastContextProvider>
        </ModalsContextProvider>
      </TPAComponentsProvider>
    </WidgetContextProvider>
  );
};

export default WidgetWrapper;
