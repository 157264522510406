import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import type {
  InjectedClassNameProps,
  InjectedDataHookProps,
} from '../../../types';
import styles from './RepositionButton.scss';
import { RepositionIcon } from './RepositionIcon';

type InjectedProps = InjectedClassNameProps & InjectedDataHookProps;

interface RepositionButtonProps extends InjectedProps {
  text: string;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

export const RepositionButton: FC<RepositionButtonProps> = ({
  dataHook,
  className,
  text,
  onClick,
  onKeyDown,
}) => {
  return (
    <button
      data-hook={dataHook}
      className={classNames(styles.button, className)}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <RepositionIcon />
      <span className={styles.text}>{text}</span>
    </button>
  );
};
