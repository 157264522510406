import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import type {
  InjectedClassNameProps,
  InjectedDataHookProps,
} from '../../../types';
import type { ButtonClickHandler } from '../Button';
import Button from '../Button';
import styles from './FollowButton.scss';
import FollowingIcon from './FollowingIcon';

type InjectedProps = InjectedClassNameProps & InjectedDataHookProps;

interface FollowButtonProps extends InjectedProps {
  followText: string;
  isFollowing: boolean;
  isDisabled?: boolean;
  unfollowText: string;
  primaryButtonClass?: string;
  secondaryButtonClass?: string;
  type: 'text' | 'icon' | 'animated';
  onClick: ButtonClickHandler;
}

const FollowButton: FC<React.PropsWithChildren<FollowButtonProps>> = ({
  className,
  dataHook,
  isFollowing,
  isDisabled,
  followText,
  unfollowText,
  primaryButtonClass,
  secondaryButtonClass,
  type,
  onClick,
}) => {
  let ffButtonClassNames;

  if ((type === 'animated' && !isFollowing) || type === 'text') {
    ffButtonClassNames = classNames(
      styles.button,
      className,
      styles.textButton,
      primaryButtonClass,
    );
  } else {
    ffButtonClassNames = classNames(
      styles.button,
      className,
      styles.iconButton,
      secondaryButtonClass,
    );
  }

  return (
    <Button
      dataHook={dataHook}
      className={ffButtonClassNames}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      <div
        className={classNames(
          styles.text,
          type === 'animated' ? styles.animated : '',
        )}
      >
        <div className={styles.verticalAlign}>
          {isFollowing ? unfollowText : followText}
        </div>
      </div>
      <FollowingIcon className={styles.icon} />
    </Button>
  );
};

export default FollowButton;
