import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useCanEditMember, useMember } from '../../../../contexts/widgets';
import settingsParams from '../../settingsParams';

export const useCustomProfile = () => {
  const { canEditMember } = useCanEditMember();
  const { customProfile } = useMember();

  if (!canEditMember) {
    const { fields, socialLinks } = customProfile;
    return {
      fields: fields.filter(({ value }) => value?.length),
      socialLinks: socialLinks.filter(({ href }) => href),
    };
  }

  return customProfile;
};

export const useOverviewRenderer = () => {
  const { isEditor } = useEnvironment();
  const { fields, socialLinks } = useCustomProfile();
  const settings = useSettings();

  const showOverviewSection = settings.get(settingsParams.showOverviewSection);
  const hasCustomProfile = fields.length > 0 || socialLinks.length > 0;

  return {
    shouldRenderOverview: showOverviewSection && (isEditor || hasCustomProfile),
  };
};
