import type { FC } from 'react';
import React from 'react';

interface CameraSmallProps {
  className: string;
}

const CameraSmall: FC<CameraSmallProps> = ({ className }) => (
  <svg className={className} width="19" height="19" viewBox="0 0 19 19">
    <g fill="none" fillRule="evenodd">
      <g fill="#000" fillRule="nonzero">
        <path
          d="M11.677 3c.388 0 .74.224.905.576L13.25 5H16c.553 0 1 .448 1 1v8c0 .552-.447 1-1 1H3c-.552 0-1-.448-1-1V6c0-.552.448-1 1-1h2.75l.668-1.424c.164-.352.517-.576.905-.576zm-.195 1H7.518L6.5 6H3v8h13V6h-3.5l-1.018-2zM9.5 7C10.88 7 12 8.12 12 9.5S10.88 12 9.5 12 7 10.88 7 9.5 8.12 7 9.5 7zm0 1C8.672 8 8 8.672 8 9.5S8.672 11 9.5 11s1.5-.672 1.5-1.5S10.328 8 9.5 8z"
          transform="translate(-853 -606) translate(803 485) translate(30 52) translate(20 69)"
        />
      </g>
    </g>
  </svg>
);

export default CameraSmall;
