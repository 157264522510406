import { useEnvironment } from '@wix/yoshi-flow-editor';
import type { FC } from 'react';
import React from 'react';

import { classes, st } from './Section.st.css';

interface SectionProps {
  className?: string;
}

export const Section: FC<React.PropsWithChildren<SectionProps>> = ({
  className,
  children,
}) => {
  const { isMobile } = useEnvironment();
  return (
    <div className={st(classes.root, { mobile: isMobile }, className)}>
      {children}
    </div>
  );
};
