import type { RoleAction } from '@wix/members-domain-ts';
import { RoleId } from '@wix/members-domain-ts';
import { useTranslation } from '@wix/yoshi-flow-editor';
import type { FC } from 'react';
import React from 'react';
import { ActionsMenuLayout } from 'wix-ui-tpa/cssVars';

import roleIcons from '../../../constants/role-icons';
import type { AdditionalAction } from '../../../types';
import { DataHook } from '../../../types';
import styles from './PopoverContent.scss';

export type OnRoleAction = (roleId: RoleId) => void;

export type OnAdditionalAction = (id: AdditionalAction['id']) => void;

interface BaseProps {
  onRoleAction: OnRoleAction;
  onAdditionalAction: OnAdditionalAction;
}

export interface PopoverContentProps extends BaseProps {
  additionalActions: AdditionalAction[];
  rolesActions: RoleAction[];
  isMobile: boolean;
}

const DIVIDER = 'divider';

const shouldRenderSeparator = (
  actionsWithIcon: RoleAction[],
  actionsWithoutIcon: (RoleAction | AdditionalAction)[],
) => {
  const hasActionsWithIcons = actionsWithIcon.length > 0;
  const hasActionsWithoutIcons = actionsWithoutIcon.length > 0;
  return hasActionsWithIcons && hasActionsWithoutIcons;
};

export const PopoverContent: FC<
  React.PropsWithChildren<PopoverContentProps>
> = ({ additionalActions, rolesActions, onRoleAction, onAdditionalAction }) => {
  const { t } = useTranslation();

  const roleIconsKeys = Object.keys(roleIcons);
  const withIcons = rolesActions.filter(({ roleId }) =>
    roleIconsKeys.includes(roleId),
  );
  let withoutIcons = rolesActions.filter(
    ({ roleId }) => !roleIconsKeys.includes(roleId),
  );
  const isSeparatorNeeded = shouldRenderSeparator(withIcons, [
    ...withoutIcons,
    ...additionalActions,
  ]);

  // Render "Manage badges" at the end if it's there
  const manageBadgesAction = withoutIcons.find(
    (r) => r.roleId === RoleId.MANAGE_BADGES,
  );
  if (manageBadgesAction) {
    // For a divider to be visible before "Manage badges" and for keys to work
    withoutIcons.push({
      action: DIVIDER,
      roleId: RoleId.MANAGE_BADGES,
      description: '',
    });
    withoutIcons = withoutIcons.filter((r) => r !== manageBadgesAction);
    withoutIcons.push(manageBadgesAction);
  }

  return (
    <ActionsMenuLayout
      className={styles.actionsMenuLayout}
      data-hook={DataHook.Popover}
      focusedIndex={0}
    >
      {withIcons.map(({ action, roleId }) => {
        const RoleIcon = roleIcons[roleId];

        return (
          <ActionsMenuLayout.Item
            content={t(action)}
            onClick={() => onRoleAction(roleId)}
            key={action}
            prefixIcon={<RoleIcon className={styles.migratedIconFill} />}
          />
        );
      })}

      {/* actionsMenuLayout expects child to have type (child.type === ...), to avoid error <></> is returned */}
      {isSeparatorNeeded ? <ActionsMenuLayout.Divider /> : <></>}

      {additionalActions.map(({ id, action }) => (
        <ActionsMenuLayout.Item
          content={t(action)}
          onClick={() => onAdditionalAction(id)}
          key={id}
        />
      ))}

      {withoutIcons.map(({ action, roleId }) => {
        if (action === DIVIDER) {
          return <ActionsMenuLayout.Divider />;
        }

        return (
          <ActionsMenuLayout.Item
            content={t(action)}
            onClick={() => onRoleAction(roleId)}
            key={action}
          />
        );
      })}
    </ActionsMenuLayout>
  );
};
