import type { FC } from 'react';
import React from 'react';

import {
  useAppSettings,
  useBlogPosts,
  useCanEditMember,
  useMember,
} from '../../../../contexts/widgets';
import EmptyState from './EmptyState';
import { shouldForceEmptyState } from './hooks';
import PostList from './PostList';
import Title from './Title';

export const BlogPosts: FC = () => {
  const { blogPosts } = useMember();
  const { canEditMember } = useCanEditMember();
  const appSettings = useAppSettings();
  const shouldShowEmptyState = canEditMember && !blogPosts.length;
  const { posts, paging, arePostsLoading } = useBlogPosts();

  return (
    <>
      <Title postCount={paging?.total} />
      {shouldShowEmptyState || shouldForceEmptyState(appSettings) ? (
        <EmptyState />
      ) : (
        <PostList
          posts={posts}
          paging={paging}
          arePostsLoading={arePostsLoading}
        />
      )}
    </>
  );
};
