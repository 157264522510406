import type { Layout, Size } from '@wix/members-badge-lib';
import { BadgeList as BadgeListComponent } from '@wix/members-badge-lib';
import { useStyles } from '@wix/tpa-settings/react';
import type { FC } from 'react';
import React from 'react';

import stylesParams from '../../../components/ProfileCard/stylesParams';
import type { BadgeSettings } from '../../../types';
import { BadgeLayout, BadgeSize, ProfileLayout } from '../../../types';

type BadgeLayoutMap = { [key in BadgeLayout]: Layout };

type BadgeSizeMap = { [key in BadgeSize]: Size };

type BadgeListProps = BadgeSettings & {
  size?: BadgeSize;
  widgetLayout?: ProfileLayout;
};

const badgeLayoutMap: BadgeLayoutMap = {
  [BadgeLayout.IconOnly]: 'icon-only',
  [BadgeLayout.NameOnly]: 'name-only',
  [BadgeLayout.NameAndIcon]: 'name-icon',
};

const badgeSizeMap: BadgeSizeMap = {
  [BadgeSize.Small]: 'small',
  [BadgeSize.Medium]: 'medium',
  [BadgeSize.Large]: 'large',
};

export const BadgeList: FC<BadgeListProps> = ({
  size,
  widgetLayout,
  maxRows,
  ...rest
}) => {
  const styles = useStyles();
  const badgeSize: BadgeSize = styles.get(stylesParams.badgeSize);
  const badgeLayout: BadgeLayout = styles.get(stylesParams.badgeLayout);
  const badgesListMaxRows =
    widgetLayout === ProfileLayout.Card && badgeLayout === BadgeLayout.IconOnly
      ? 3
      : maxRows;

  return (
    <BadgeListComponent
      {...rest}
      borderRadius={styles.get(stylesParams.badgeCornerRadius)}
      font={styles.get(stylesParams.badgeFont).family ?? ''}
      background={styles.get(stylesParams.showBadgeBackground)}
      remainderColor={styles.get(stylesParams.memberNameResponsiveColor).value}
      size={size ? badgeSizeMap[size] : badgeSizeMap[badgeSize]}
      layout={badgeLayoutMap[badgeLayout]}
      maxRows={badgesListMaxRows}
    />
  );
};
