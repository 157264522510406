import { useTranslation } from '@wix/yoshi-flow-editor';
import type { FC } from 'react';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';

import type { SocialLink as ISocialLink } from '../../../../../types';
import { iconMap } from './icons';
import { classes } from './SocialLink.st.css';

export const SocialLink: FC<Omit<ISocialLink, 'id'>> = ({
  name,
  socialType,
  href,
}) => {
  const { t } = useTranslation();
  const Icon = iconMap[socialType];

  if (!href) {
    return (
      <Text className={classes.placeholder} tagName="div">
        {Icon && <Icon className={classes.icon} />}
        {t('about.widget.not-published')}
      </Text>
    );
  }

  return (
    <a
      className={classes.link}
      href={href as string}
      rel="noreferrer"
      target="_blank"
    >
      <Text className={classes.text} tagName="div">
        {Icon && <Icon className={classes.icon} />}
        {name}
      </Text>
    </a>
  );
};
