import {
  isContentStateEmpty,
  isDraftContent,
  isRichContent,
  isRichContentEmpty,
} from '@wix/ricos';

import { useCanEditMember, useMember } from '../../../../../contexts/widgets';
import type { MemberAbout } from '../../../../../types';

export const isAboutEmpty = (about: MemberAbout | null) => {
  if (isRichContent(about)) {
    return isRichContentEmpty(about);
  }

  if (isDraftContent(about)) {
    return isContentStateEmpty(about);
  }

  return true;
};

export const useAboutRendered = () => {
  const { about } = useMember();
  const { canEditMember } = useCanEditMember();
  const isAboutFilled = !isAboutEmpty(about);

  return { shouldRenderAbout: canEditMember || isAboutFilled };
};
