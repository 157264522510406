import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';

import { classes } from './SectionTitle.st.css';

export const SectionTitle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text className={classes.root} tagName="h3">
      {children}
    </Text>
  );
};
