import type { TFunction } from '@wix/yoshi-flow-editor';
import { ToastSkin } from 'wix-ui-tpa/cssVars';

import { Toast } from '../../../../../types';

export const getToastContent = (toast: Toast, t: TFunction) => {
  let toastSkin;
  let toastContent: string | null;

  switch (toast) {
    case Toast.AboutPublish:
      toastContent = t('about-page.success-message');
      toastSkin = ToastSkin.success;
      break;
    case Toast.AboutPublishErrorTooManyLinks:
      toastContent = t('about-page.error-message.too-many-links');
      toastSkin = ToastSkin.error;
      break;
    case Toast.AboutPublishErrorTextTooLong:
      toastContent = t('about-page.error-text-too-long');
      toastSkin = ToastSkin.error;
      break;
    case Toast.AboutPublishErrorUnknown:
      toastContent = t('about-page.error-unknown');
      toastSkin = ToastSkin.error;
      break;
    default:
      toastContent = null;
      toastSkin = ToastSkin.success;
  }

  return {
    toastContent,
    toastSkin,
  };
};
