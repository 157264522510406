import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import stylesParams from '../../../../stylesParams';

const pageContainerWidth = 980;
const pageMargin = 60;

export const usePostWidth = () => {
  const styles = useStyles();
  const { dimensions } = useEnvironment();
  const cardWidth = styles.get(stylesParams.blogPostCardWidth);
  const cardMargin = styles.get(stylesParams.blogPostCardMargin);
  const cardListWidth =
    dimensions.width && dimensions.width < pageContainerWidth
      ? dimensions.width - pageMargin
      : pageContainerWidth - pageMargin;

  let columnsPerRow = Math.floor(cardListWidth / cardWidth) || 1;
  const gapCount = columnsPerRow - 1;

  if (columnsPerRow * cardWidth + cardMargin * gapCount > cardListWidth) {
    columnsPerRow--;
  }

  const cardSpacing =
    cardListWidth -
    cardWidth * columnsPerRow -
    cardMargin * (columnsPerRow - 1);

  return {
    postWidth: Math.round(cardWidth + cardSpacing || 1 / columnsPerRow || 1),
  };
};
