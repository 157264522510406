import {
  DevianArt,
  Facebook,
  Instagram,
  Link,
  LinkedIn,
  Odnoklassniki,
  Pinterest,
  SoundCloud,
  TikTok,
  Tumblr,
  Twitter,
  Vimeo,
  VKontakte,
  Youtube,
} from '@wix/wix-ui-icons-common/on-stage';
import type { FC } from 'react';

import { SocialType } from '../../../../../types';

export const iconMap: Record<SocialType, FC<{ className?: string }>> = {
  [SocialType.DEVIANTART]: DevianArt,
  [SocialType.FACEBOOK]: Facebook,
  [SocialType.INSTAGRAM]: Instagram,
  [SocialType.LINKEDIN]: LinkedIn,
  [SocialType.ODNOKLASSNIKI]: Odnoklassniki,
  [SocialType.OTHER]: Link,
  [SocialType.PINTEREST]: Pinterest,
  [SocialType.SOUNDCLOUD]: SoundCloud,
  [SocialType.TIKTOK]: TikTok,
  [SocialType.TUMBLR]: Tumblr,
  [SocialType.TWITTER]: Twitter,
  [SocialType.UNKNOWN]: Link,
  [SocialType.VIMEO]: Vimeo,
  [SocialType.VKONTAKTE]: VKontakte,
  [SocialType.YOUTUBE]: Youtube,
};
