import { useEnvironment } from '@wix/yoshi-flow-editor';

import { blockedMemberId } from '../../../../constants';
import { useAppSettings, useMember } from '../../../../contexts/widgets';
import type { ControllerProps } from '../../../../types';
import {
  SettingsSection,
  SettingsSectionState,
  SettingsTab,
} from '../../../../types';

interface UseEmptyStateRendererOptions {
  shouldRenderDescription: boolean;
  shouldRenderBadges: boolean;
  shouldRenderOverview: boolean;
  shouldRenderBlogPosts: boolean;
}

type ShouldForceEmptyStateProps = ControllerProps['appSettings'] & {
  isMobile: boolean;
};

const shouldForceEmptyState = ({
  tabOpened,
  sectionOpened,
  sectionState,
  isMobile,
}: ShouldForceEmptyStateProps) => {
  if (sectionState !== SettingsSectionState.Empty) {
    return false;
  }

  return (
    (tabOpened === SettingsTab.Sections &&
      sectionOpened === SettingsSection.About) ||
    (tabOpened === SettingsTab.Design &&
      sectionOpened === SettingsSection.TextStyle) ||
    (isMobile && tabOpened === SettingsTab.Design)
  );
};

export const useEmptyStateRenderer = ({
  shouldRenderDescription,
  shouldRenderBadges,
  shouldRenderOverview,
  shouldRenderBlogPosts,
}: UseEmptyStateRendererOptions) => {
  const { isMobile } = useEnvironment();
  const appSettings = useAppSettings();

  if (shouldForceEmptyState({ ...appSettings, isMobile })) {
    return { shouldRenderEmptyState: true };
  }

  return {
    shouldRenderEmptyState:
      !shouldRenderDescription &&
      !shouldRenderBadges &&
      !shouldRenderOverview &&
      !shouldRenderBlogPosts,
  };
};

export const useBlockedEmptyStateRenderer = () => {
  const { id } = useMember();
  return { shouldRenderBlockedEmptyState: id === blockedMemberId };
};
