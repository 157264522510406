import {
  AdminIcon,
  BlogEditorIcon,
  BlogWriterIcon,
  ForumModeratorIcon,
} from '@wix/members-area-commons-ts';
import { RoleId } from '@wix/members-domain-ts';
import type { FC, SVGAttributes } from 'react';

interface RoleIcons {
  [key: string]: FC<React.PropsWithChildren<SVGAttributes<SVGElement>>>;
}

const roleIcons: RoleIcons = {
  [RoleId.ADMIN]: AdminIcon,
  [RoleId.SET_BLOG_EDITOR]: BlogEditorIcon,
  [RoleId.SET_FORUM_MODERATOR]: ForumModeratorIcon,
  [RoleId.SET_BLOG_WRITER]: BlogWriterIcon,
};

export default roleIcons;
