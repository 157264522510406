import {
  pluginDividerViewer,
  pluginEmojiViewer,
  pluginGiphyViewer,
  pluginImageViewer,
  pluginLinkViewer,
  pluginVideoViewer,
} from '@wix/ricos';

import application from '../../../../../../../.application.json';

export const biSettings = {
  platform: 'Livesite' as const,
  consumer: 'members-about-ooi',
  usage: 'About (preview)',
  containerId: application.appDefinitionId,
};

export const plugins = [
  pluginImageViewer({ disableExpand: true }),
  pluginVideoViewer(),
  pluginDividerViewer(),
  pluginGiphyViewer(),
  pluginEmojiViewer(),
  pluginLinkViewer(),
];
