import type { MediaPlatformImage } from '@wix/members-domain-ts';
import { Camera } from '@wix/wix-ui-icons-common/on-stage';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import type { FC } from 'react';
import React, { useRef } from 'react';
import { Avatar } from 'wix-ui-tpa/cssVars';

import { acceptableImageExtensions } from '../../../constants/image';
import { getElementId } from '../../../services/html-attributes';
import type { InjectedClassNameProps, Nullable } from '../../../types';
import {
  DataHook,
  ElementId,
  ProfileImage,
  ProfileLayout,
} from '../../../types';
import styles from './AvatarInput.scss';

const WIX_MEDIA_INDICATOR = 'static.wixstatic.com/media';
const WIX_MP_INDICATOR = 'images-wixmp';

type AvatarProps = {
  url: Nullable<string | MediaPlatformImage>;
  imageType: ProfileImage;
  imageSize: number;
  profileLayout?: ProfileLayout;
  canUpload?: boolean;
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  isMobile?: boolean;
} & InjectedClassNameProps;

const shouldOptimizeImage = (url: string) => {
  return (
    !url.includes('v1/fill') &&
    (url.includes(WIX_MEDIA_INDICATOR) || url.includes(WIX_MP_INDICATOR))
  );
};

const toOptimizedImage = (url: string, imageSize: number) => {
  if (shouldOptimizeImage(url)) {
    return `${url}/v1/fill/w_${imageSize},h_${imageSize},q_80/file.webp`;
  }

  return url;
};

const AvatarInput: FC<AvatarProps> = ({
  url,
  imageType,
  className = '',
  name,
  onClick,
  canUpload,
  profileLayout,
  imageSize,
  isMobile = false,
}) => {
  const { t } = useTranslation();
  const { widgetId } = useEnvironment();
  const inputRef = useRef<HTMLInputElement>(null);

  const inputId = getElementId(widgetId, ElementId.ProfilePhotoInput);
  const imageURL =
    typeof url === 'object' ? url?.file_name : toOptimizedImage(url, imageSize);

  if (imageType === ProfileImage.None) {
    return null;
  }

  const isAvatarSquare = imageType === ProfileImage.Square;

  const wrapperClasses = {
    [styles.wrapper]: true,
    [styles.onHover]: canUpload,
    [styles.fullWidth]: profileLayout === ProfileLayout.FullWidth,
    [styles.card]: profileLayout === ProfileLayout.Card,
    [styles.mobile]: isMobile,
    [styles.squareOverlay]: isAvatarSquare,
  };

  const avatarClasses = {
    [styles.avatar]: true,
    [styles.squareAvatar]: isAvatarSquare,
  };

  const ariaLabel = imageURL
    ? t('profile-widget.change-profile-photo')
    : t('profile-widget.add-profile-photo.aria-label');

  return (
    <div>
      {canUpload && (
        <input
          id={inputId}
          className={styles.uploadInput}
          type="file"
          accept={acceptableImageExtensions}
          onChange={onClick}
          ref={inputRef}
          tabIndex={-1}
        />
      )}
      <button
        className={classNames(wrapperClasses, className)}
        onClick={() => inputRef.current?.click()}
        aria-label={ariaLabel}
        disabled={!canUpload}
      >
        <Avatar
          name={name}
          src={imageURL}
          data-hook={DataHook.ProfilePhoto}
          className={classNames(avatarClasses)}
        />
        {!isMobile && (
          <div className={styles.pictureWrapper}>
            <div className={styles.iconOval}>
              <Camera />
            </div>
          </div>
        )}
      </button>
    </div>
  );
};

export default AvatarInput;
