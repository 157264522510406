import classNames from 'classnames';
import type { FC, MouseEventHandler } from 'react';
import React from 'react';

import type { InjectedDataHookProps } from '../../../types';
import ChatButton from '../ChatButton';
import FollowButton from '../FollowButton';
import styles from './FollowOrChatButton.scss';

const classNameBySize = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
};

interface FollowOrChatButtonProps extends InjectedDataHookProps {
  followText: string;
  unfollowText: string;
  chatText: string;
  isFollowing: boolean;
  shouldShowChatButton: boolean;
  showFollowButton: boolean;
  onFFClick: MouseEventHandler<HTMLButtonElement>;
  onChatClick: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const FollowOrChatButton: FC<
  React.PropsWithChildren<FollowOrChatButtonProps>
> = ({
  dataHook,
  size = 'large',
  followText,
  unfollowText,
  chatText,
  isFollowing,
  isDisabled,
  shouldShowChatButton,
  onFFClick,
  onChatClick,
  showFollowButton,
}) => {
  const ffButtonClassName = classNames(
    styles.ffButton,
    !shouldShowChatButton && ['medium', 'large'].includes(size)
      ? styles.fullWidth
      : '',
  );

  return (
    <div className={classNames(styles.buttonsContainer, classNameBySize[size])}>
      {showFollowButton && (
        <FollowButton
          dataHook={dataHook}
          className={ffButtonClassName}
          primaryButtonClass={styles.primaryButton}
          secondaryButtonClass={styles.secondaryButton}
          type={shouldShowChatButton ? 'animated' : 'text'}
          unfollowText={unfollowText}
          followText={followText}
          isFollowing={isFollowing}
          isDisabled={isDisabled}
          onClick={onFFClick}
        />
      )}

      {shouldShowChatButton && (
        <ChatButton
          onClick={onChatClick}
          className={styles.chatButton}
          primaryButtonClass={styles.primaryButton}
          secondaryButtonClass={styles.secondaryButton}
          isFollowing={isFollowing}
          isDisabled={isDisabled}
          size={size}
          text={chatText}
          type="animated"
        />
      )}
    </div>
  );
};

export default FollowOrChatButton;
