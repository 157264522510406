import { useTranslation } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import type { InjectedClassNameProps } from '../../../types';
import styles from './Blocked.scss';

type BlockedProps = InjectedClassNameProps;

export const Blocked: FC<BlockedProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <span className={classNames(className, styles.container)}>
      {t('MemberRoles.badge.blocked')}
    </span>
  );
};
