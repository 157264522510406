import type { FC } from 'react';
import React, { createContext } from 'react';

import type { ControllerProps } from '../../types';

export const WidgetContext = createContext<ControllerProps>(null!);

export const WidgetContextProvider: FC<
  React.PropsWithChildren<ControllerProps>
> = (props) => (
  <WidgetContext.Provider value={props}>
    {props.children}
  </WidgetContext.Provider>
);
