import { maMiscClickUou } from '@wix/bi-logger-members-app-uou/v2';
import {
  Comment,
  LikeHeart,
  LikeHeartFill,
  Visible,
} from '@wix/wix-ui-icons-common/on-stage';
import type { VisitorLogger } from '@wix/yoshi-flow-editor';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import type { MouseEventHandler } from 'react';
import React, { forwardRef } from 'react';
import { Divider, Text } from 'wix-ui-tpa/cssVars';

import { useSiteHandlers } from '../../../../../../contexts/widgets';
import type { BlogPost } from '../../../../../../types';
import { BlogNavigationReference, DataHook } from '../../../../../../types';
import Cover, { shouldRenderCover } from './Cover';
import { classes, st } from './Post.st.css';

const formatDate = (dateString: string, language: string) => {
  return new Date(dateString).toLocaleDateString(language, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

const reportPostClick = (bi: VisitorLogger) => {
  bi.report(maMiscClickUou({ referralInfo: BlogNavigationReference.ViewPost }));
};

export const Post = forwardRef<HTMLAnchorElement, BlogPost>(
  (
    {
      coverMedia,
      firstPublishedDate,
      minutesToRead,
      url,
      title,
      excerpt,
      postCountInfo,
    },
    ref,
  ) => {
    const bi = useBi();
    const { t } = useTranslation();
    const { language, isPreview, isMobile } = useEnvironment();
    const { navigateToPath } = useSiteHandlers();

    const postUrl = `${url.base}${url.path}`;
    const handlePostLinkClick: MouseEventHandler = (event) => {
      reportPostClick(bi);

      if (isPreview) {
        event.preventDefault();
        event.stopPropagation();
        navigateToPath(url.path!);
      }
    };

    return (
      <a
        ref={ref}
        data-hook={DataHook.Post}
        className={st(classes.root, { mobile: isMobile })}
        href={postUrl}
        onClick={handlePostLinkClick}
      >
        {shouldRenderCover(coverMedia) && <Cover coverMedia={coverMedia} />}
        <div className={st(classes.content)}>
          <Text tagName="div" className={classes.dateBar}>
            <span className={st(classes.text, classes.publishDate)}>
              {formatDate(firstPublishedDate, language)}
            </span>
            <span className={st(classes.text, classes.dateBarSeparator)}>
              {' ∙ '}
            </span>
            <span className={st(classes.text, classes.readingTime)}>
              {minutesToRead} {t('recent-posts.post-card.minutes')}
            </span>
          </Text>
          <div className={classes.postLink}>
            <Text tagName="div" className={classes.title}>
              {title}
            </Text>
            <Text tagName="div" className={classes.excerpt}>
              {excerpt}
            </Text>
          </div>
        </div>
        <div className={st(classes.footer)}>
          <Divider className={classes.divider} />
          <Text tagName="div" className={classes.statsBar}>
            <div className={classes.viewsAndCommentsCounter}>
              <div className={st(classes.counter, classes.viewsCounter)}>
                <Visible />
                <span>{postCountInfo.views ?? 0}</span>
              </div>
              <div className={st(classes.counter, classes.commentsCounter)}>
                <Comment />
                <span>{postCountInfo.comments ?? 0}</span>
              </div>
            </div>
            <div className={classes.likesCounter}>
              {postCountInfo.likes! > 0 && <span>{postCountInfo.likes}</span>}
              {postCountInfo.likes ? <LikeHeartFill /> : <LikeHeart />}
            </div>
          </Text>
        </div>
      </a>
    );
  },
);
