import { maMiscClickUou } from '@wix/bi-logger-members-app-uou/v2';
import { useSettings } from '@wix/tpa-settings/react';
import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import type { MouseEventHandler } from 'react';

import {
  useAppSettings,
  useBlogPosts,
  useCanEditMember,
  useMember,
  useSite,
  useSiteHandlers,
} from '../../../../contexts/widgets';
import type { ControllerProps } from '../../../../types';
import {
  BlogNavigationReference,
  SettingsSection,
  SettingsSectionState,
  SettingsTab,
} from '../../../../types';
import settingsParams from '../../settingsParams';
import { getIsBlogContributor } from './utils';

export const shouldForceEmptyState = ({
  tabOpened,
  sectionOpened,
  sectionState,
}: ControllerProps['appSettings']) => {
  return (
    tabOpened === SettingsTab.Sections &&
    sectionOpened === SettingsSection.PostList &&
    sectionState === SettingsSectionState.Empty
  );
};

const shouldRenderBlogPosts = (
  showBlogPostsSection: boolean,
  hasBlogContent: boolean,
  hasBlogContributorPermissions: boolean,
) => {
  return (
    showBlogPostsSection && hasBlogContent && hasBlogContributorPermissions
  );
};

export const useBlogPostsRendered = () => {
  const settings = useSettings();
  const appSettings = useAppSettings();
  const { roles, blogPosts } = useMember();
  const { canEditMember } = useCanEditMember();
  const { isBlogInstalled } = useSite();

  const showBlogPostsSection = settings.get(
    settingsParams.showBlogPostsSection,
  );
  const hasBlogContent =
    canEditMember || shouldForceEmptyState(appSettings) || blogPosts.length > 0;
  const hasBlogContributorPermissions = getIsBlogContributor(
    isBlogInstalled,
    roles,
  );

  return {
    shouldRenderBlogPosts: shouldRenderBlogPosts(
      showBlogPostsSection,
      hasBlogContent,
      hasBlogContributorPermissions,
    ),
  };
};

const reportClickProps = maMiscClickUou({
  referralInfo: BlogNavigationReference.CreatePost,
});

export const useCreatePostClickHandler = () => {
  const bi = useBi();
  const { isPreview } = useEnvironment();
  const { createBlogPostUrl } = useBlogPosts();
  const { navigateToPath } = useSiteHandlers();

  const handleCreatePostLinkClick: MouseEventHandler = (event) => {
    bi.report(reportClickProps);

    if (isPreview) {
      event.preventDefault();
      event.stopPropagation();
      navigateToPath(createBlogPostUrl.relativeUrl);
    }
  };

  return { handleCreatePostLinkClick };
};
