import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import type { FC } from 'react';
import React from 'react';
import { Button, ButtonPriority, Text } from 'wix-ui-tpa/cssVars';

import {
  useCanEditMember,
  useMember,
  useMemberHandlers,
} from '../../../../contexts/widgets';
import { DataHook } from '../../../../types';
import settingsParams from '../../settingsParams';
import EditIcon from './EditIcon';
import { classes, st } from './Header.st.css';

const formatDate = (dateString: string, language: string) =>
  new Date(dateString).toLocaleDateString(language, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

const MobileEditProfileButton: FC = () => {
  const settings = useSettings();
  const { navigateToMyAccount } = useMemberHandlers();

  return (
    <Button
      type="button"
      data-hook={DataHook.EditProfileButton}
      className={classes.editProfileButtonMobile}
      priority={ButtonPriority.secondary}
      upgrade={true}
      onClick={() => navigateToMyAccount()}
    >
      <div className={classes.editProfileButtonContentMobile}>
        <div className={classes.editProfileIconMobile}>
          <EditIcon />
        </div>
        <span>{settings.get(settingsParams.headerSectionButtonText)}</span>
      </div>
    </Button>
  );
};

const DesktopEditProfileButton: FC = () => {
  const settings = useSettings();
  const { navigateToMyAccount } = useMemberHandlers();

  return (
    <Button
      type="button"
      data-hook={DataHook.EditProfileButton}
      className={classes.editProfileButton}
      priority={ButtonPriority.secondary}
      prefixIcon={<EditIcon />}
      upgrade={true}
      onClick={() => navigateToMyAccount()}
    >
      <div className={classes.editProfileButtonContent}>
        {settings.get(settingsParams.headerSectionButtonText)}
      </div>
    </Button>
  );
};

export const Header: FC = () => {
  const settings = useSettings();
  const { isMobile, language } = useEnvironment();
  const { createdDate } = useMember();
  const { canEditMember } = useCanEditMember();

  return (
    <>
      <div className={st(classes.titleWrapper, { mobile: isMobile })}>
        <Text
          className={classes.title}
          tagName="h2"
          id="members-area-page-title"
        >
          {settings.get(settingsParams.headerSectionTitle)}
        </Text>
        {createdDate ? (
          <Text tagName="div" className={classes.createdDate}>
            {`${settings.get(
              settingsParams.headerSectionSubtitle,
            )} ${formatDate(createdDate, language)}`}
          </Text>
        ) : null}
        {canEditMember &&
          (isMobile ? (
            <MobileEditProfileButton />
          ) : (
            <DesktopEditProfileButton />
          ))}
      </div>
    </>
  );
};
