import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import React, { type ChangeEvent, type FC, useRef, useState } from 'react';
import {
  Button,
  ButtonPriority,
  Dialog,
  Text,
  TextField,
} from 'wix-ui-tpa/cssVars';

import { useWidget } from '../../../../contexts/widgets/hooks';
import { DataHook, type ModalProps } from '../../../../types';
import classes from './EditProfileDetailsModal.scss';

const MAX_NAME_LENGTH = 255;
const MISSING_NICKNAME_ERROR_MESSAGE =
  'profile-widget.edit-details-modal.error-message.required-field';

export const EditProfileDetailsModal: FC<ModalProps> = ({
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation();
  const { isRTL, isMobile } = useEnvironment();
  const { member, handlers, computed } = useWidget();

  const { updateProfileDisplayInfo } = handlers;
  const { showTitle } = computed;

  const initialNickname = member.name;
  const initialTitle = member.title;

  const [nickname, setNickname] = useState(initialNickname);
  const [title, setTitle] = useState(initialTitle ?? '');
  const [validationActive, setValidationActive] = useState(false);
  const [nicknameErrorMessage, setNicknameErrorMessage] = useState('');
  const nicknameInputRef = useRef<TextField>(null);

  const validateFields = () => {
    if (!validationActive) {
      setValidationActive(true);
    }

    if (!nickname.trim()) {
      nicknameInputRef.current?.focus();
      setNicknameErrorMessage(t(MISSING_NICKNAME_ERROR_MESSAGE));
      return false;
    }

    return true;
  };

  const hasDataChanged = () => {
    const nicknameChanged = nickname.trim() !== initialNickname;
    const titleChanged = showTitle && title.trim() !== initialTitle;

    return nicknameChanged || titleChanged;
  };

  const handleNicknameChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    const { value } = changeEvent.target;

    if (validationActive) {
      if (!value.trim()) {
        setNicknameErrorMessage(t(MISSING_NICKNAME_ERROR_MESSAGE));
      } else {
        setNicknameErrorMessage('');
      }
    }

    setNickname(value);
  };

  const handleTitleCHange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    setTitle(changeEvent.target.value);
  };

  const getProfileInfoPayload = () => {
    return {
      id: member.uid,
      profile: {
        nickname: nickname.trim(),
        ...(showTitle && { title: title.trim() }),
      },
    };
  };

  const onConfirm = () => {
    if (!hasDataChanged()) {
      return onClose();
    }

    if (!validateFields()) {
      return;
    }

    updateProfileDisplayInfo(getProfileInfoPayload());
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={classNames({
        [classes.root]: true,
        [classes.rtl]: isRTL,
        [classes.isMobile]: isMobile,
      })}
      childrenWrapperClassName={classes.content}
    >
      <div
        className={classes.contentInner}
        data-hook={DataHook.EditProfileDetailsModal}
      >
        <Text className={classes.title} tagName="h2">
          {t('profile-widget.edit-details-modal.heading')}
        </Text>

        <div className={classes.fields}>
          <TextField
            className={classes.field}
            inputClassName={classes.inputRoot}
            label={t('profile-widget.edit-details-modal.display-name.label')}
            maxLength={MAX_NAME_LENGTH}
            value={nickname}
            required={true}
            ref={nicknameInputRef}
            onChange={handleNicknameChange}
            error={!!nicknameErrorMessage}
            errorMessage={nicknameErrorMessage ?? ''}
          />

          {showTitle && (
            <TextField
              className={classes.field}
              inputClassName={classes.inputRoot}
              label={t('profile-widget.edit-details-modal.title.label')}
              value={title}
              onChange={handleTitleCHange}
            />
          )}
        </div>

        {isMobile && <div className={classes.spacer}></div>}

        <div className={classes.ctaContainerOuter}>
          <div className={classes.ctaContainer}>
            <Button
              upgrade
              priority={ButtonPriority.basicSecondary}
              className={classNames({
                [classes.button]: true,
                [classes.secondary]: true,
              })}
              onClick={onClose}
            >
              {t('profile-widget.edit-details-modal.cancel-button')}
            </Button>

            <Button
              upgrade
              priority={ButtonPriority.basic}
              className={classNames({
                [classes.button]: true,
                [classes.primary]: true,
              })}
              onClick={onConfirm}
            >
              {t('profile-widget.edit-details-modal.save-button')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
